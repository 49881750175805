import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import kebabCase from 'lodash/kebabCase'
import React from 'react'
import Helmet from 'react-helmet'
import Button from '../components/button'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default function ArtPostTemplate({ data }) {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <Helmet>
        <link
          rel="preconnect dns-prefetch"
          href="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
        />
      </Helmet>
      <div className="article">
        <h1 className="text-4xl">{frontmatter.title}</h1>
        <div className="md:flex lg:block xl:flex md:-mx-4 lg:mx-0 xl:-mx-4 max-w-2xl">
          <div className="md:px-4 lg:px-0 xl:px-4 w-full md:w-2/3 lg:w-full xl:w-2/3">
            <Img sizes={frontmatter.img.childImageSharp.sizes} alt="frontmatter.title" />
          </div>
          <div className="pb-4 md:px-4 lg:px-0 xl:px-4 md:w-1/3 lg:w-full xl:w-1/3">
            <h2 class="text-xl">Information</h2>
            <dl class="my-4">
              <dt className="mr-1 float-left font-bold">Category:</dt>
              <dd>
                <Link
                  className="text-grey-darkest"
                  to={`/art/${kebabCase(frontmatter.categories)}/`}
                >
                  {frontmatter.categories}
                </Link>
              </dd>
              <dt className="mr-1 float-left font-bold">Medium:</dt>
              <dd>{frontmatter.medium}</dd>
              <dt className="mr-1 float-left font-bold">Dimensions:</dt>
              <dd>{frontmatter.dimensions}</dd>
            </dl>
            <div className="mt-8 clearfix">
              <Button href={`/art/${kebabCase(frontmatter.categories)}/`}>
                Back to {frontmatter.categories}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ArtPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        categories
        medium
        dimensions
        title
        printInfo
        printPrice
        img {
          childImageSharp {
            sizes(maxWidth: 1500) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
